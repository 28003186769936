.parent-accordion .ant-collapse-header {
  border: 1px solid #d9d9d9;
}

.parent-accordion {
  border: none;
}

.parent-accordion .ant-collapse-item {
  border-bottom: none;
}

.parent-accordion .ant-collapse-content-box .child-accordion {
  border: none;
}

.parent-accordion .ant-collapse-content-box .child-accordion .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.parent-accordion .ant-collapse-content-box .child-accordion .ant-collapse-item:last-child {
  border-bottom: none;
}

.parent-accordion .ant-collapse-content-box {
  padding-top: 0;
}

.parent-accordion .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.parent-accordion .accordion-node-title-container {
  display: flex;
  align-items: center;
  column-gap: 1vw;
}

.parent-accordion .accordion-title img {
  height: 2.5vw;
}

.parent-accordion .accordion-title .ant-collapse-header-text > div {
  display: flex;
}

.parent-accordion .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.parent-accordion .accordion-pagination {
  padding: 1vw;
  background: #fafafa;
  text-align: right;
}

.parent-accordion .ant-collapse-content {
  border-top: none !important;
}

.parent-accordion .leaf-node-icon img {
  height: 2vw;
  border-radius: 0.5vw;
}

.parent-accordion .leaf-node-container {
  margin: 0.5vw;
  margin-left: 30px;
  display: flex;
  display: flex;
  column-gap: 1vw;
  align-items: center;
  cursor: pointer;
  overflow-wrap: anywhere;

  width: fit-content;
}

.parent-accordion .accordion-node-title-container img {
  border-radius: 0.5vw;
}

.leafNodeHead.gridType .ant-collapse-content-box {
  display: grid;
  grid-template-columns: auto auto auto;
}

.leafNodeHead.listType .ant-collapse-content-box {
  display: grid;
}

.accordion-tile-container {
  display: flex;
  grid-column-gap: 0.21vw;
}

.accordion-tile-container img {
  height: 1.7vw !important;
}
