.Plans {
  @media (max-width: 575px) {
    padding: 24px;
  }
  background: #000000;
  padding: 1.8vw 10vw;
  color: #ffffff;
}

.FaqTitle {
  font-weight: 900;
  font-size: 2.08vw;
  color: #ffffff;
  margin-bottom: 1.82vw;
}

.Plans .Head {
  display: flex;
  justify-content: space-between;
}

.Plans .Title {
  @media (max-width: 575px) {
    font-size: 32px;
    margin-top: 20px;
  }
  font-weight: 900;
  font-size: 1.87vw;
  color: #ffffff;
  text-transform: uppercase;
}

.Plans .Subtitle {
  @media (max-width: 575px) {
    font-size: 16px;
  }
  color: #c7c7c7;
  font-weight: 500;
  font-size: 0.93vw;
}

.Plans .HeadRight {
  @media (max-width: 575px) {
    display: none;
  }
  display: flex;
  column-gap: 1vw;
  align-items: center;
}

.CardContainer {
  @media (max-width: 575px) {
    flex-direction: column;
  }
  margin-top: 5vw;
  display: flex;
  column-gap: 1vw;
  align-items: flex-start;
  justify-content: center;
}

.FaqContainer {
  margin-top: 10vw;
}

.Plans .CalculateButton {
  width: 9.9vw;
  height: 2.6vw;
}

.ContactUs {
  @media (max-width: 575px) {
    width: auto;
    display: block;
    padding: 10vw;
    flex-direction: column;
    height: auto;
    text-align: left;
    align-items: flex-start;
    background: transparent linear-gradient(0deg, #0e213e 0%, #05204a 100%) 0% 0% no-repeat padding-box;
    position: relative;
  }
  display: none;
}

.PlanSubtitle {
  @media (max-width: 575px) {
    width: auto;
    font-size: 28px;
    font-weight: 900;
  }
}

.PlanCalculateButton {
  @media (max-width: 575px) {
    height: auto;
    width: fit-content;
    margin: 10px 0;
  }
}

.PlanCalculateButton > div {
  @media (max-width: 575px) {
    font-size: 16px;
    padding: 10px;
  }
  background: transparent linear-gradient(180deg, #e7f7f8 0%, #5b9bd6 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.93vw;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.DolphinLogo {
  @media (max-width: 575px) {
    position: absolute;
    right: 10px;
    top: 0;
  }
}

.DolphinLogo img {
  @media (max-width: 575px) {
    width: 220px;
    height: 220px;
  }
}

.PlanDesc {
  @media (max-width: 575px) {
    font-size: 16px;
  }
}
