.typing-animation {
  color: #fff;
  font-size: 16px;
  background-color: #002140;
  border-radius: 5px;
  padding: 15px 12px 1px 15px;
  margin: 8px;
  display: inline-block;
  border-radius: 5px;
}

.wave-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.wave-dot {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 5px;
  opacity: 0;
  animation: wave-animation 1.2s infinite ease-in-out;
}

.show {
  opacity: 1;
}

.dot1 {
  animation-delay: 0.1s;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.3s;
}

@keyframes wave-animation {
  0%,
  60%,
  100% {
    opacity: 0;
    transform: translateY(0);
  }
  20%,
  40% {
    opacity: 1;
    transform: translateY(-10px);
  }
}
