.billing-tab {
  padding: 20px;
  border-radius: 8px 8px 0 0;
  background-color: #fff;
}

.billing-tab .ant-tabs-card .ant-tabs-tab-active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: #0054a1;
  border-radius: 0 0 15px 15px;
}


.billing-dropdown{
    display: flex;
    gap: 20px;
}

.billing-dropdown .submit-btn{
    border-radius: 5px;
}