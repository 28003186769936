.scan-history-card {
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  padding: 20px !important;
  background-color: #f7f9fc !important;
  margin-bottom: 10px !important;
  display: flex !important;
  flex-direction: column !important;
}

.scan-history-card .card-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scan-history-card .card-content {
  display: flex;
  gap: 5px;
}

.scan-history-card .ant-card-body {
  padding: 0;
}
.scan-history-card .card-title {
  font-size: 15px;
  font-weight: 600;
  color: #0054a1;
  text-decoration: none;
}

.scan-history-card .content {
  font-size: 15px;
  font-weight: 600;
  color: grey;
  text-decoration: none;
}

.scan-history-card .card-button {
  background-color: #0054a1;
  border: none;
  color: white;
  width: 150px;
  border-radius: 15px;
  font-size: 15px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scan-history-card .card-button:hover {
  background-color: #003e7e;
}
