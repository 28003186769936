.productConfig {
  margin: 20px;
}

.title {
  font-size: 18px;
  font-weight: 400;
}

.title .active-page {
  font-weight: bold; /* Example: make it bold */
  color: #007bff; /* Example: blue color */
  font-size: 16px; /* Example: adjust the font size */
  padding-left: 5px; /* Optional: some padding */
}

.navigation-buttons {
  display: grid;
  row-gap: 1vw;
  margin-top: 30px;
}

.navigation-buttons > div {
  text-transform: uppercase;
  background: #f2f9ff;
  font-size: 12px;
  letter-spacing: 1.25px;
  width: 280px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}

.schedules-container {
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 15px 23px;

  display: grid;
  row-gap: 25px;
}

.schedules-container .title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.schedules-container .timezone {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  margin-bottom: 10px;
}

.schedules-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons-container {
  display: flex;
  column-gap: 1vw;
}

.edit-button {
  display: flex;
  width: 78px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #5b9bd6;
  background: #fff;
  cursor: pointer;

  color: #5b9bd6;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
}

.delete-button {
  display: flex;
  width: 96px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  border: 1px solid #ff003d;
  background: #fff;
  cursor: pointer;

  color: #ff003d;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
}

.timezone-container {
}

.timezone-table {
  border-radius: 2px;
  border: 1px solid #c4c4c4;
  background: #fff;
  width: 100%;
  padding: 0.5vw;
}
.timezone-table td {
  padding: 5px 10px;
  text-align: center;
}

.timezone-table .table-head {
  color: #5b9bd6;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.Product-config-card-img-top {
  width: 40%;
  height: auto;
  max-width: 100%;
}

.Product-config-card-text {
  text-align: justify;
}

.Product-config-custom-card {
  box-shadow: 0 4px 8px rgba(2, 63, 143, 0.3);
  border: none;
  cursor: pointer;
}

.Product-config-card-title {
  text-decoration: underline;
  color: #0054a1;
}
