.bill-section {
  margin-bottom: 40px;
}
.billing {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 34px;
  margin-top: 14px;
}
.billing a {
  color: #5b9bd6;
  text-decoration: none;
  display: block;
}
.billing a img {
  margin-right: 10px;
}
.billing p {
  color: #53a258;
  font-size: 12px;
  line-height: 24px;
}
.billing.outstanding {
  background: #ffeff3;
  border: 1px solid #ff003d;
}
.billing.outstanding p {
  color: #ff003d;
}
.billing label {
  color: #606060;
  font-size: 14px;
  line-height: 24px;
}
.billing span {
  color: #000;
  font-size: 14px;
  line-height: 24px;
}
.billing .form-group {
  margin-bottom: 5px;
}
.payment-status {
  max-width: 504px;
  margin: 60px auto;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding: 85px 35px 46px;
}
.payment-status img {
  margin-bottom: 116px;
}
.payment-status h2 {
  margin-bottom: 20px;
  font-size: 34px;
}
.payment-status p {
  margin-bottom: 100px;
  color: #666666;
  font-size: 14px;
}
.payment-status .buttons button{
  margin-bottom: 16px;
  font-size: 14px;
}
.legend-container{
  max-height: 300px;
  overflow-y: auto;
}
.ant-popover-inner-content{
  padding: 0px !important;
}
