.left-menu-links {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  min-height: 500px;
  margin-bottom: 50px;
  position: relative;
}
.left-menu-links .link {
  color: #75777b;
  text-decoration: none;
  font-size: 15px;
  margin-bottom: 8px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  column-gap: 0.51vw;
}
.left-menu-links .link.active {
  color: #fff;
  ''position: relative;
  background: #5b9bd6;
  padding: 4px 7px 4px 7px;
  border-radius: 4px;
  /* height: 30.5px; */
}
.left-menu-links .link.active::before {
  /* content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    background: #4e8ebb;
    top: 0px;
    left: -14px;
    border-radius: 5px; */
}
.left-menu-links .link img {
  position: relative;
  top: -2px;
  margin-right: 10px;
  text-align: left;
  max-width: 15px;
  max-height: 20px;
}
.company {
  position: absolute;
  bottom: 40px;
}
.company .copy {
  color: #5b9bd6;
  margin-left: 11px;
  font-size: 12px;
}

ul.group-options {
  list-style-type: none;
  width: 80%;
  left: 0;
  height: 500px;
  padding-left: 0;
}

ul.group-options li {
  font-size: 16px;
  cursor: pointer;
  padding: 0.7vw;
  border-radius: 5px;
}

ul.group-options li img {
  height: 3vw;
}

li.selected-group-option {
  background: #5b9bd6;
  color: #ffffff;
}

.dropdown-btn {
  background: #eef6ff;
  border-radius: 4px;
}
