.card-img-top {
    width: 40%;
    height: auto;
    max-width: 100%;
  }
  
  .card-text {
    text-align: justify;
  }

  .custom-card {
    box-shadow: 0 4px 8px rgba(2, 63, 143, 0.3);
    border: none; 
    cursor: pointer;
  }

  .card-title {
    text-decoration: underline;
    color: #0054A1;
  }
  