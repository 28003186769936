.access-keys-page {
}
.access-key-details {
  margin-bottom: 40px;
}
.single-key {
  border: 1px solid #c4c4c4;
  border-bottom: 0;
  align-items: center;
  padding: 16px 20px;
}
.access-key-details .single-key:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.access-key-details .single-key:last-child {
  border: 1px solid #c4c4c4;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.single-key .image img {
  max-width: 40px;
  margin-top: 4px;
}
.single-key .key-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
}
.single-key .key-detail span:not(:first-child) {
  color: #666666;
  font-size: 12px;
}
.events button,
.access-key-title .add-key {
  border: 1px solid #5b9bd6;
  background: #fff;
  border-radius: 4px;
  color: #5b9bd6;
  padding: 8.5px 8px;
  border-radius: 40px;
  min-width: 96px;
}
.events button.delete {
  border: 1px solid #ff003d;
  color: #ff003d;
  margin-left: 11px;
}
.access-key-title h3 {
  flex: 1;
}
.access-key-title {
  margin-bottom: 20px;
}
.access-key-title .add-key {
  font-size: 14px;
  line-height: 1;
  background: #5b9bd6;
  color: #fff;
  padding: 6px 29px;
}
.add-key-form {
  margin-bottom: 30px;
}
.add-key-form .form-label {
  margin-bottom: 0;
}
.add-key-form .form-group {
  margin-bottom: 13px;
}
.add-key-form button[type='submit'] {
  padding: 8px 19px;
  margin-top: 20px;
}
.add-key-form .btn {
  /* margin-top: 20px;*/
}
.single-key .events img {
  width: 30px;
}
