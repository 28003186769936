.PlanCard {
  @media (max-width : 575px) {
    width: 100%;
    margin: 5% 0;
    border-radius: 5px;

  }
  background: transparent linear-gradient(112deg, #000000 0%, #1e4582 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #08277b;
  border-radius: 0.26vw;
  width: 19.2vw;
  cursor: pointer;
  transition: transform 0.3s ease, height 0.3s ease;
}

.PlanCard.Popular {
  @media (max-width : 575px) {
    margin: 5% 0;
  }
  margin-top: -2.5vw;
  background: transparent linear-gradient(180deg, #00cbff 0%, #141f9c 100%) 0% 0% no-repeat padding-box;
}

.List {
  list-style: none;
  padding-left: 0;
  margin-top: 1vw;
}

.ListItem {
  @media (max-width : 575px) {
    font-size: 14px;
  }
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  font-size: 0.83vw;
}

.Bullet {
  @media (max-width : 575px) {
    margin-right: 10px;
    width: 5px;
    height: 5px;
  }
  width: 0.4vw;
  height: 0.4vw;
  background: #3d72fe;
  margin-right: 0.5vw;
  border-radius: 50%;
}

.Head {
  @media (max-width : 575px) {
    height: auto;
    line-height: 2;
    padding: 15px;
  }
  height: 14.14vw;
  padding: 1.82vw 1.56vw;
  background: #000e20 0% 0% no-repeat padding-box;
  border: 1px solid #08277b;
  border-radius: 0.26vw 0.26vw 0px 0px;
  opacity: 1;
  backdrop-filter: blur(1.56vw);
  -webkit-backdrop-filter: blur(1.56vw);
}

.Title {
  @media (max-width : 575px) {
    font-size: 24px;
  }
  color: #ffffff;
  font-weight: 600;
  font-size: 1.35vw;
  text-transform: uppercase;
}

.Subtitle {
  @media (max-width : 575px) {
    font-size: 14px;
  }
  font-size: 0.72vw;
  font-weight: normal;
  color: #ffffff;
}

.AmountContainer {
  margin-top: 1vw;
  display: flex;
  align-items: center;
  column-gap: 0.41vw;
}

.Amount {
  color: #00cbff;
  text-transform: uppercase;
  font-size: 3.125vw;
  font-weight: 500;
}

.Usage {
  font-size: 0.83vw;
  font-weight: normal;
  color: #ffffff;
  margin-top: 1.1vw;
}

.Body {
  @media (max-width : 575px) {
    padding: 15px;
  }
  padding: 1.82vw 1.56vw;
}

.Body .Title {
  @media (max-width : 575px) {
    font-size: 22px;
    line-height: 2;
  }
  font-size: 1.25vw;
}

.Ribbon {
  @media (max-width : 575px) {
    font-size: 20px;
    height: auto;
    padding: 10px;
  }
  background: #00cbff;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.35vw;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.6vw;
  border-radius: 0.2vw;
}

.SignupButton{
  @media (max-width : 575px) {
    display: none;
  }
  width: 16.5vw;
  height: 3vw;
  margin: auto;
  margin-bottom: 1vw;
}


.Popular .Bullet{
  background: #fff;
}

.PlanCard:hover{
  transform: scale(1.02); /* Slightly zoom in on hover */
  background: transparent linear-gradient(180deg, #00EDFF 0%, #0075AA 100%) 0% 0% no-repeat padding-box;
  transition: transform 0.2s, background-color 0.2s; /* Smooth transition */
}


.PlanCard:hover .Bullet{
  background: #fff;
}