/* Tab Navigation */
.sticky-top ul.nav {
  width: 65% !important;
}
ul.nav {
  width: 60%;
  height: 3.5rem;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39a2db;
  border-radius: 2rem;
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}
ul.nav li {
  width: 50%;
  height: 100%;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.nav li:first-child {
  border-radius: 0;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

ul.nav li:last-child {
  border-radius: 0;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}

ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
ul.nav li.active {
  background: #39a2db;
  color: #ffffff;
}

/* First and Second Tab Styles */
.FirstTab p,
.SecondTab p {
  font-size: 2rem;
  text-align: center;
}
