.iconStyle {
  color: white !important;
  font-size: 45px !important;
  background: linear-gradient(180deg, #1890ff 0%, #0139a3 100%);
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  position: fixed;
  right: 20px;
  bottom: 20px;
  box-shadow: 0 0 30px rgba(24, 144, 255, 0.8);
}

.chatWindow {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.chatHeader {
  background-color: #002140;
  color: white;
  padding: 10px;
  text-align: center;
}

.chatMessages {
  padding: 10px;
  overflow: auto;
  min-height: 200px;
  min-width: 300px;
  height: 100%;
  word-wrap: break-word;
}

.userMessage {
  margin-bottom: 8px;
  text-align: right;
  word-wrap: break-word;
  width: 100%;
  background-color: #002140;
  color: white;
  padding: 3px 12px;
  border-radius: 5px;
  white-space: pre-wrap;
  width: fit-content;
  margin-left: auto;
}

.aiMessage {
  margin-bottom: 8px;
  text-align: left;
  word-wrap: break-word;
  width: 100%;
  background-color: #eee;
  color: #333;
  padding: 3px 12px;
  border-radius: 5px;
  white-space: pre-wrap;
  margin-right: auto;
  width: fit-content;
}

.chatInputContainer {
  border-top: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.chatTextArea {
  flex: 1;
  margin-right: 8px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
  overflow: auto;
}

.sendButton {
  background-color: #002140;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 8px;
}
