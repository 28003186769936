.dashboard-container {
  padding: 5px;
}

.col {
  margin-bottom: 15px;
}
.back-btn {
  margin: 10px;
  border-radius: 5px;
  background: none;
  border: transparent;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0 10px;
}

.scan-history {
  margin: 2vw 5vw;
}

.finding-cards {
  display: flex;
  gap: 10px;
  cursor: pointer;
  height: 100%;
}

.severity-cards {
  display: flex;
  gap: 10px;
  cursor: pointer;
  height: 100%;
}

.piechart {
  display: flex;
  box-shadow: 5px 5px 10px #888888;
  border-radius: 15px;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chart {
  cursor: pointer;
}

.noHistory {
  align-items: center;
  text-align: center;
  justify-content: center;
}

.loading {
  align-items: center;
  text-align: center;
  justify-content: center;
}
