.access-key-container {
  margin: 10px;
  padding: 20px;
}

.access-key-title {
  display: flex;
  justify-content: space-between;
}

.aws-title {
  font-weight: bold; /* Example: make it bold */
  color: black; /* Example: blue color */
  font-size: 25px; /* Example: adjust the font size */
  padding-left: 5px; /* Optional: some padding */
}

.aws-btns {
  display: flex;
  gap: 10px; /* Space between buttons */
  justify-content: flex-end; /* Align buttons to the right */
}

.aws-btns .btn {
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 10px 20px; /* Padding for a clickable area */
  font-size: 14px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer on hover */
  display: flex;
  align-items: center; /* Vertically center icon and text */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.aws-btns .add-btn {
  background-color: #0054a1; /* Original blue color */
}

.aws-btns .add-btn:hover {
  background-color: #007bb5; /* Updated lighter blue on hover */
}

.aws-btns .add-btn:active {
  background-color: #004b87; /* Updated darker blue on click */
}

.aws-btns .refresh-btn {
  background-color: #0054a1; /* Same blue as "Add New" */
}

.aws-btns .refresh-btn:hover {
  background-color: #007bb5; /* Updated lighter blue on hover */
}

.aws-btns .refresh-btn:active {
  background-color: #004b87; /* Updated darker blue on click */
}

.account-id-col {
  display: flex;
  gap: 0.5vw;
}

/* AWS icon styling */
.aws-icon {
  height: 2.3vw;
  width: auto;
}

/* Action buttons container */
.action-buttons {
  display: flex !important;
  gap: 0.6vw !important;
  justify-content: center !important;
}

/* General action button styling */
.action-btn {
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  padding: 5px 10px !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  transition: background-color 0.3s ease !important;
}

.edit-icon-container {
  width: 25px;
  height: 25px;
  line-height: 1.8;
  background-color: #007bff; /* Blue background */
  border-radius: 50%;
  margin-left: 10px;
}

.delete-icon-container {
  width: 25px;
  height: 25px;
  line-height: 1.8;
  background-color: red; /* Blue background */
  border-radius: 50%;
  margin-left: 10px;
}

.verify-icon-container {
  width: 25px;
  height: 25px;
  line-height: 1.8;
  background-color: green; /* Blue background */
  border-radius: 50%;
  margin-left: 10px;
}

.button-icon {
  color: white; /* White icon */
  font-size: 13.5px;
}

.edit-btn p {
  margin-top: 15px;
  color: #333 !important;
}

.edit-btn span {
  color: white;
}

.verify-btn p {
  margin-top: 15px;
  color: #333 !important;
}

.verify-btn p {
  color: white;
}

.delete-btn p {
  margin-top: 15px;
  color: #333 !important;
}

.delete-btn span {
  color: white;
}

.action-btn:hover {
  color: black; /* Change text color to black on hover */
  background-color: transparent; /* No background color on hover */
  border: none; /* Remove border on hover */
}

.action-btn:hover .icon-container {
  background-color: #007bff; /* Keep the background color of the icon */
  color: white; /* Icon color stays white */
}

.edit-btn:hover .icon-container {
  background-color: #0056b3; /* Darker blue on hover */
}

.verify-btn:hover .icon-container {
  background-color: #006400; /* Darker green on hover */
}

.delete-btn:hover .icon-container {
  background-color: #b30000; /* Darker red on hover */
}

.ghost {
  background: none;
  border: 1px solid #ddd;
}

.aws-table {
  border-radius: 4px;
  border-collapse: collapse;
  width: 100%;
}

.aws-table .ant-table {
  background: transparent;
}

.aws-table .ant-table-thead > tr > th {
  background: #0054a1;
  color: white;
  border-color: white;
  text-align: center;
  font-weight: bold;
  white-space: normal;
  height: auto;
  padding: 16px 8px;
}

.aws-table .ant-table-thead > tr > th .ant-table-column-title {
  white-space: normal;
}

.aws-table .ant-table-tbody > tr > td {
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  background: white;
}

.aws-table .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black;
}

.aws-table .ant-table-tbody > tr > td {
  text-align: center;
}

.aws-table .ant-table-tbody > tr.trend-up {
  background-color: #fff1f0;
}

.aws-table .ant-table-tbody > tr.trend-down {
  background-color: #f6ffed;
}

.aws-table .ant-table-tbody > tr:hover > td {
  background-color: #e6f7ff;
}
