.FaqTitle {
  @media (max-width : 575px) {
    font-size: 28px;
  }
  font-weight: 900;
  font-size: 2.08vw;
  color: #ffffff;
  margin-bottom: 1.82vw;
}

.FaqContainer {
  background: #000000;
  padding: 0vw 10vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
  color: #ffffff;
}
