.Footer {
  background: transparent linear-gradient(180deg, #000000 0%, #1e4582 100%) 0% 0% no-repeat padding-box;
  font-family: 'Jost', sans-serif;
  padding: 1.8vw 10vw;
  color: #ffffff;
}

.Title {
  @media (max-width : 575px) {
    font-size: 18px;
    height: auto;
    margin-top: 35px;
  }
  font-weight: bold;
  font-size: 1.04vw;
  color: #ffffff;
  height: 2.4vw;
}

.Points {
  @media (max-width : 575px) {
    font-size: 15px;
    line-height: 2;
  }
  display: grid;
  margin-top: 0.7vw;
  grid-row-gap: 0.5vw;
  font-size: 0.8vw;
}

.Points > div {
  cursor: pointer;
}
.TopIcons {
  display: flex;
  column-gap: 0.3vw;
  width: fit-content;
  margin-left: auto;
}
.TopIcons img {
  height: 5.8vw;
  width: 5.3vw;
  margin-top: -2vw;
}

.Logo img {
  @media (max-width : 575px) {
    width: auto;
    margin-bottom: 20px;
    height: auto;
  }
  width: 3.6vw;
  height: 2.4vw;
}

.Container {
  @media (max-width : 575px) {
    flex-direction: column;
  }
  display: flex;
  justify-content: space-between;
  margin-top: 1.5vw;
}

.SubscribeBox > div:first-child {
  @media (max-width : 575px) {
    font-size: 15px;
    margin-top: 10px;
  }
  color: #8f8da2;
  font-size: 0.8vw;
}

.SearchInput {
  @media (max-width : 575px) {
    width: auto;
    margin-top: 5px;
  }
  margin-top: 1vw;
  position: relative;
  width: 22vw;
  display: flex;
  border-radius: 0.3vw;
}

.SearchInput input {
  @media (max-width : 575px) {
    height: 50px;
  }
  width: 100%;
  height: 100%;
  border: none;
  padding-right: 9vw;
  padding-left: 0.88vw;
  outline: none;
  color: black;
  height: 3.5vw;
  border-radius: 0.3vw;
}

.SignupButton {
  @media (max-width : 575px) {
    width: auto;
    height: auto;
  }
  width: 6.8vw;
  height: 2.6vw;
  position: absolute;
  right: 0.51vw;
  top: 13%;
}

.CopyRights{
  @media (max-width : 575px) {
    flex-direction: column;
    align-items: baseline;
    line-height: 4;
    margin-top: 24px;
  }
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.2vw;
}

.CopyRights > div {
  @media (max-width : 575px) {
    font-size: 14px;
  }
}

.SocialIcons{
  @media (max-width : 575px) {
    column-gap: 5vw;
    margin-bottom: 30px;
  }
    display: flex;
    column-gap: 1vw;
}

.SocialIcons img{
  @media (max-width : 575px) {
    width: 30px;
    height: 30px;
  }
    height: 1.7vw;
    width: 1.7vw;
}

.RegisterButton {
  @media (max-width : 575px) {
    font-size: 10px;
    padding: 10px;
  }
  background: transparent linear-gradient(180deg, #5d54fc 0%, #001182 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.93vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 100%;
  height: 100%;
  cursor: pointer;
}