.contextbarbackground {
  background: linear-gradient(180deg, #eef6ff 0%, #f5faff 100%);
}

.contextbarbackground .ant-tabs {
  height: 90%;
}

.contextbarbackground .ant-tabs-content-holder {
  overflow: auto;
}

.btn-primary-spacing {
  margin-right: 5px;
  /*margin-left: 10px;*/
  margin-bottom: 5px !important;
  margin-top: 20px !important;
}

.contextBarElement {
  margin-top: 10px !important;
}

.nomarginslist {
  margin: 0;
  padding: 0;
}

.prop-text {
  color: #212121;
  font-weight: 500;
  margin: 10px 0px 10px 10px;
}

.node-properties {
  height: 100%;
  overflow-y: auto;
  word-wrap: break-word;
}

.node-property {
  border-top: 1px solid #d8e4ef;
  border-down: 1px solid #d8e4ef;
  padding: 10px 5px;
}

.node-property-name {
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #666666;
}

.node-property-value {
  font-weight: 500;
  letter-spacing: 0.25px;
  color: #212121;
}

.other-props {
  padding-left: 10px;
}

.node-tag {
  padding: 3px;
  margin: 5px;
}

.contextbar-title {
  margin-top: 15px;
  margin-left: 10px;
}

.contextbar-actionContainer {
  display: grid;
}

.contextbar-inputContainer {
  padding: 1vw;
}

.contextbar-dropdownContainer {
  padding: 0 1vw;
  margin-top: 1vw;
}

.contextbar-dropdownContainer .dropdown-toggle {
  background: #5b9bd6 !important;
  border-radius: 40px !important;
  outline: none;
  border: none;
}

.flex {
  display: flex;
}

.context-bar-head {
  display: flex;
  column-gap: 1vw;
  margin-left: 1vw;
}

.context-bar-head img {
  border-radius: 0.5vw;
}
.context-bar-title {
  margin: 0;
}

.context-bar-subtitle {
  font-size: 1vw;
}

.contextbarbackground .close-icon {
  cursor: pointer;
  display: flex;
  color: black;
  margin-right: 11px;
  margin-top: 5px;
  /* Add any desired margin */
}

.contextbarbackground .close-icon:hover {
  color: #000; /* Replace with desired hover color */
}

.collapse-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 1px solid #5b9bd6;
  border-radius: 40px;
  padding: 0.61vw 3vw;
  margin-left: auto;
  margin-right: 2vw;
}

.contextbar-actionContainer .schedule-title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.contextbar-actionContainer .schedule-container {
  display: grid;
  row-gap: 10px;
}

.schedule-container .timezone-table {
  font-size: 12px;
}
.schedule-container .timezone-table td {
  padding: 1px 2px;
  font-size: 12px;
}
.schedule-container .table-head {
  font-size: 14px;
}

.schedule-container .schedule-sub-title {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  letter-spacing: 0.25px;
}

.schedule-collapse {
  background: linear-gradient(180deg, #eef6ff 0%, #f5faff 100%) !important;
}

.schedule-collapse .ant-collapse-content {
  background: linear-gradient(180deg, #eef6ff 0%, #f5faff 100%) !important;
}

.valid-layout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5vw;
  font-size: 1.15vw;
}
