.finding-card {
  border-radius: 8px;
  text-align: center;
  width: 100%;
  box-shadow: 5px 5px 10px #888888;
}

.finding-card-title {
  color: white;
  font-size: 20px;
  margin-bottom: 30px;
}

.card-content {
  font-size: 45px;
  font-style: bold;
  font-weight: 900;
  color: white;
}
