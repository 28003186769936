.filters-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 5px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.filter-label {
  font-weight: bold;
  text-align: right;
  color: #007bff;
}

.filter-select {
  width: 180px;
  border-radius: 8px;
  border: 1px solid #ced4da;
  background: linear-gradient(145deg, #ffffff, #e9ecef);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease, box-shadow 0.3s ease;
}

.filter-select:hover {
  background: linear-gradient(145deg, #e9ecef, #ffffff);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1), 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.filter-select .ant-select-selector {
  border: none;
  background: transparent;
}

.filter-select .ant-select-arrow {
  color: #007bff;
}
