.HeaderWithBg {
  @media (max-width : 575px) {
    background-size: auto;
  }
  height: 100%;
  background-size: cover;
  background-position: center;
  padding: 1.8vw 10vw;
  color: #fff;
}

.Container {
  padding: 1.8vw 10vw;
}

.SlicesLeft {
  position: absolute;
  left: 10vw;
  top: -1vw;
}

.SlicesLeft img {
  width: 30vw;
}
.Title {
  @media (max-width : 575px) {
    font-size: 24px;
    font-weight: 900;
  }
  font-weight: 600;
  font-size: 2.6vw;
  text-transform: uppercase;

  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vw;
}
