.new-btn-primary {
  background: transparent linear-gradient(180deg, #e7f7f8 0%, #5b9bd6 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.93vw;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.new-btn-secondary {
  background: transparent linear-gradient(180deg, #5d54fc 0%, #001182 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.93vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ReactModal__Overlay {
  z-index: 3 !important;
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.ReactModal__Content {
  padding: 1.4vw !important;
}
