.detailed-view-tabs {
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.detailed-view-tabs .ant-tabs-nav {
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.detailed-view-tabs .ant-tabs-tab {
  justify-content: center;
  flex: 1;
  width: 520px;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
  background-color: #f0f0f0;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  transition: background-color 0.3s;
}

.detailed-view-tabs .ant-tabs-tab:hover {
  background-color: #e6f7ff;
}

.detailed-view-tabs .ant-tabs-tab-active {
  background-color: #0054a1;
  border-bottom: 2px solid #1890ff;
  color: white !important;
}

.detailed-view-tab-pane {
  padding: 16px;
}

.detailed-view-container {
  background-color: #f9f9f9;
}

.detailed-view-header {
  color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.detailed-view-header h2 {
  color: #007bff;
}

.filters-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 5px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-item {
  display: flex;
  align-items: center;
}

.filter-label {
  font-weight: bold;
  text-align: right;
  color: #007bff;
}

.filter-select {
  width: 230px;
  border-radius: 8px;
  border: 1px solid #ced4da;
  background: linear-gradient(145deg, #ffffff, #e9ecef);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1),
    1px 1px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease, box-shadow 0.3s ease;
}

.filter-select:hover {
  background: linear-gradient(145deg, #e9ecef, #ffffff);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1),
    1px 1px 4px rgba(0, 0, 0, 0.3);
}

.filter-select .ant-select-selector {
  border: none;
  background: transparent;
}

.filter-select .ant-select-arrow {
  color: #007bff;
}

.accounts-selection-container {
  display: flex;
  align-items: center;
}

.date-selection-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.date-label {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #007bff !important;
}

.detailed-view-date-picker.custom-date-picker {
  width: 100%;
  border-radius: 4px;
}

.detailed-view-date-picker.ant-picker {
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.detailed-view-date-picker.ant-picker-focused {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.detailed-view-date-picker.ant-picker-input {
  background-color: #fff;
  border-radius: 4px;
}

.detailed-view-date-picker.ant-picker-dropdown {
  border-radius: 4px;
}

.detailed-view-date-picker.ant-picker-header {
  background-color: #1890ff;
  color: #fff;
}

.detailed-view-date-picker.ant-picker-cell-selected {
  background-color: #1890ff;
  color: #fff;
}

.detailed-view-date-picker.ant-picker-cell:hover {
  background-color: #e6f7ff;
}

.go-button {
  border-radius: 5px !important;
}

.go-button:hover {
  background-color: #0056b3;
}

.detailed-view-container {
  font-family: Arial, sans-serif;
  padding: 10px;
}

.detailed-view-header {
  margin-bottom: 20px;
}

.accounts-selection-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 5px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.expandButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.cost-daily-table {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
  background-color: white;
}

.cost-cell {
  background-color: #f0faff;
}

.cost-daily-table table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px;
  font-size: 14px;
}

.cost-daily-table th,
td {
  padding: 8px 12px;
  text-align: center;
}

.cost-daily-table th {
  background-color: #0054a1;
  font-weight: bold;
  color: white;
  border-color: white;
}

.product-header,
.usage-type-row,
.item-type-row {
  text-align: left;
}

.cost-daily-table .expand-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #0056b3;
}

.cost-daily-table .product-row,
.usage-type-row,
.item-type-row {
  transition: background-color 0.3s ease;
}

.cost-daily-table .product-row:hover,
.usage-type-row:hover,
.item-type-row:hover {
  background-color: #f0f4f7;
}

.cost-daily-table td[colspan] {
  padding-left: 0;
}

.cost-daily-table td {
  vertical-align: top;
}

.cost-weekly-table {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.cost-weekly-table table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px;
  font-size: 14px;
}

.cost-weekly-table th,
.cost-weekly-table td {
  padding: 8px 12px;
  text-align: left;
}

.cost-weekly-table th {
  background-color: #0054a1;
  font-weight: bold;
  color: white;
  border-color: white;
}

.cost-weekly-table .product-header,
.cost-weekly-table .usage-type-row,
.cost-weekly-table .item-type-row {
  text-align: left;
}

.cost-weekly-table .expand-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #0056b3;
}

.cost-weekly-table .product-header {
  padding-left: 0;
}

.cost-weekly-table .usage-type-name {
  padding-left: 20px;
}

.cost-weekly-table .item-type-name {
  padding-left: 40px;
}

.cost-weekly-table .cost-cell {
  text-align: center;
}

/* CSS for the title and account IDs */
.aggregate-title-container {
  text-align: center;
  margin: 20px 0 20px 0;
}

.aggregate-title {
  font-size: 24px;
  font-weight: bold;
}

.aggregate-account-ids {
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: #555;
  margin-top: 5px;
}
