.FeaturesCard {
  @media (max-width : 575px){
    width: 100%;
    height: auto;
    margin: 5% 0;
    padding: 10% 5%;
    border-radius: 5px;
  }
  width: 19.2vw;
  height: 22.1vw;
  background: transparent linear-gradient(106deg, #000000 0%, #1e4582 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #08277b;
  border-radius: 0.2vw;
}

.FeaturesCard .Container {
  margin-left: 1.5vw;
  margin-top: 3vw;
}

.FeaturesCard .Container img {
  @media (max-width : 575px){
    height: 40px;
  }
  height: 3.3vw;
}

.FeaturesCard .Title {
  @media (max-width : 575px){
    font-size: 18px;
    height: auto;
    padding: 5% 0;
  }
  font-weight: 600;
  font-size: 1.25vw;
  color: #ffffff;
  margin-top: 1.7vw;
  letter-spacing: 0px;
  line-height: 1.2;
}

.FeaturesCard .Subtitle {
  @media (max-width : 575px){
    font-size: 14px;
    height: auto;
    width: auto;
  }
  font-size: 0.83vw;
  font-weight: normal;
  color: #ffffff;
  margin-top: 1vw;
  width: 16.3vw;
  height: 5.3vw;
}

.LearnMoreContainer{
@media (max-width : 575px){
  font-size: 12px;
  margin-top: 10px;
}
color: #5B9BD6;
text-transform: uppercase;
font-size: 0.67vw;
font-weight: 600;
margin-top: 0.8vw;
cursor: pointer;
}

.LearnMoreBorder {
  @media (max-width : 575px){
    width: 4vw;
  }
    background: #5B9BD6 0% 0% no-repeat padding-box;
    height: 3px;
    width: 1vw;
}