.sidebar-container {
  background: linear-gradient(180deg, #eef6ff 0%, #f5faff 100%);
  height: 85vh;
  /* border: 3px solid blueviolet; */
  overflow: hidden;
}

.sidebar-pallette {
  background: #15171c;
  height: 25vh;
  border: 3px solid blueviolet;
}

.commmon-tools-pallette {
  background: black;
  height: 15vh;
  border: 2px solid darksalmon;
}

.btn-primary-spacing {
  margin-right: 5px;
  margin-left: auto;
  margin-bottom: auto;
  margin-top: auto;
}

.toolbar-panel {
  margin-top: 10px !important;
  /*border: 1px solid white;*/
  /* display: flex;   */
  justify-content: center;
  align-items: center;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.sidebar.icons,
.sidebar.context {
  width: 0;
  position: relative;
  z-index: 3;
  top: 0;
  /* left: -250px; */
  /* background: linear-gradient(180deg, #EEF6FF 0%, #F5FAFF 100%); */
  /* background-color: #15171c; */
  padding: 60px 0px 0 0px;
  /* transition: 0.5s; */
  box-sizing: border-box;
}
.sidebar.context {
  flex: 0 1 0%;
  padding: 0;
}
.sidebar.icons.open {
  width: 250px;
  margin-left: 0px;
  left: 0px;
  padding: 60px 8px 0 8px;
  /* overflow-y: auto; */
  /* overflow-x: auto; */
}
.sidebar.context.open {
  flex: 0 1 22%;
  padding: 0;
}
.sidebar.context.expanded {
  flex: 0 1 50%;
}
/* .sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidebar a:hover {
  color: #f1f1f1;
} */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #15171c;
  color: white;
  padding: 10px 15px;
  border: none;
  position: absolute;
  right: -48px;
  top: 0;
}
.openbtn.right {
  background-color: #15171c;
  border: 3px solid #15171c;
  color: white;
  padding: 5px;
}
.openbtn img {
  width: 40px;
}
.sidebar.context .openbtn {
  left: -53px;
  right: auto;
}
.openbtn:hover {
  background-color: #444;
}

#dropdown-basic-button {
  margin: auto;
}
