.severity-card {
  border-radius: 8px;
  text-align: center;
  width: 100%;
  box-shadow: 5px 5px 10px #888888;
}

.severity-card-title {
  color: white;
  font-weight: 800;
  font-size: 18px;
}

.severity-card-header {
  color: white;
}

.severity-card-content {
  font-size: 35px;
  color: white;
}
