.legal-container .container {
  display: grid;
  justify-content: left;
  margin: 2vw auto;
  max-width: 800px;
  font-size: 1.1vw;
}

.legal-container .text {
  text-align: left;
  margin: 0 1rem;
  line-height: 20px;
  font-style: normal;
  font-size: 0.9vw;
  color: #45474b;
  font-weight: 300;
}
.legal-container .heading {
  text-align: left;
  margin: 0 1rem;
  font-size: 3.4vw;
  font-weight: 300;
  color: #212529;
}
.legal-container .sub-heading {
  text-align: left;
  margin: 0 1rem;
  font-size: 1vw;
  font-weight: 300;
  margin-bottom: 1vw;
  color: #646669;
}

.legal-container .p {
  font-size: 1.1vw;
}

.legal-container .li {
  font-size: 1.1vw;
}
.legal-container .point-heading {
  text-align: left;
  margin: 0 1rem;
  font-size: 1vw;
  font-weight: 600;
  margin-top: 1vw;
  margin-bottom: 1vw;
  color: #212529;
}

.legal-container .point-heading-italic {
  text-align: left;
  margin: 0 1rem;
  font-size: 1vw;
  font-weight: 600;
  font-style: italic;
  margin-top: 1vw;
  margin-bottom: 1vw;
  color: #212529;
}
