.legal-container {
  display: grid;
  justify-content: left;
  margin: 2vw auto;
  max-width: 800px;
  font-size: 1.1vw;
}

.legal-container .text {
  text-align: left;
  margin: 0 1rem;
  line-height: 1.5;
  font-style: normal;
  font-size: 1.09vw;
  font-weight: 460;
}

.legal-container .heading {
  text-align: left;
  margin: 0 1rem;
  margin-bottom: 1vw;
  font-size: 2.2vw;
  font-weight: 600;
}
.legal-container .sub-heading {
  text-align: left;
  margin: 0 1rem;
  font-size: 1vw;
  font-weight: 300;
  margin-bottom: 1vw;
}

.legal-container .p {
  font-size: 1.1vw;
}

.legal-container .ol {
  font-size: 1.1vw;
}
.legal-container .li {
  font-size: 1.1vw;
}
.legal-container .point-heading {
  text-align: left;
  margin: 0 1rem;
  font-size: 1vw;
  font-weight: 600;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.legal-container .point-heading-italic {
  text-align: left;
  margin: 0 1rem;
  font-size: 1vw;
  font-weight: 600;
  font-style: italic;
  margin-top: 1vw;
  margin-bottom: 1vw;
}
