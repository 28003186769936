/* AwsAccountSetupAndVerifier.css */

/* Modal Styles */
.aws-modal-content-wrapper {
  max-height: 600px; /* Adjust as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 16px; /* Optional: to add some spacing */
}

.ant-modal {
  border-radius: 8px;
}

.ant-modal-header {
  background-color: #0050b3;
}

.ant-modal-title {
  font-size: 20px;
  color: white;
}

.ant-modal-body {
  padding: 24px;
}

/* Steps Styles */
.step-content {
  margin-left: 10px;
}

.steps-action {
  display: flex;
  justify-content: flex-end;
  margin: 5px 30px 0 0;
}

.steps-content h4 {
  font-size: 18px;
  margin-bottom: 16px;
}

.process-steps {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  align-items: flex-start;
}

.step {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.step-number {
  background-color: #0050b3;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}

.step-connector {
  position: absolute;
  left: 3%; /* Center the connector relative to the step */
  top: 50px; /* Adjust this value to position the connector */
  width: 2px; /* Width of the connector line */
  height: 56px;
  background-color: #007bff; /* Change this color as needed */
  transform: translateX(-50%); /* Center the connector */
}

/* Verification Info Styles */
.verification-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;
}

.verification-info ul {
  list-style-type: disc;
  padding-left: 20px;
}

/* Script Tab Styles */
.script-tab {
  margin-bottom: 20px;
}

.script-tab .ant-btn {
  margin-right: 10px;
}

.script-tab pre {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
}

/* Consolidated Scripts Tab Styles */
.consolidated-scripts-tab {
  margin-bottom: 20px;
}

.consolidated-scripts-tab h3 {
  margin-bottom: 10px;
}

.consolidated-scripts-tab pre {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
}

.consolidated-scripts-tab .ant-btn {
  margin-right: 10px;
}

/* Buttons */
.ant-btn {
  border-radius: 4px;
}

.ant-btn-primary {
  background-color: #0050b3;
  border-color: #0050b3;
}

.ant-btn-primary:hover {
  background-color: #004d99;
  border-color: #004d99;
}

.ant-btn-primary:focus {
  box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.2);
}
