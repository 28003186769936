.Header {
  @media (max-width : 575px) {
    height: 300px;
  }
  height: 24.5vw;
}

.Security {
  background: #080c12;
  padding: 6.25vw 10vw;
}

.RightTitle {
  @media (max-width : 575px) {
    font-size: 18px;
    margin-top: 20px;
  }
  font-weight: 600;
  font-size: 1.25vw;
  color: #ffffff;
}

.RightDesc {
  @media (max-width : 575px) {
    font-size: 14px;
    width: auto;
  }
  font-size: 0.83vw;
  color: #ffffff;
  width: 40.1vw;
  margin-top: 1vw;
}

.SecurityCard {
  @media (max-width : 575px) {
    width: 50%;

  }
  border: 1px solid #08277b;
  background: transparent linear-gradient(104deg, #000000 0%, #1e4582 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #08277b;
  border-radius: 0.26vw 0.26vw 0.26vw 0px;
  width: 18.12vw;
}

.SecurityContainer {
  @media (max-width : 575px) {
    flex-direction: column;
    margin-top: 20px;
  }
  display: flex;
  margin-top: 2vw;
  justify-content: space-between;
}

.Title {
  @media (max-width : 575px) {
    font-size: 31px;
  }
  font-weight: 900;
  font-size: 2.08vw;
  color: #ffffff;
}

.SecurityCardLarge {
  @media (max-width : 575px) {
    width: auto;
  }
  border: 1px solid #08277b;
  background: transparent linear-gradient(104deg, #000000 0%, #1e4582 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #08277b;
  border-radius: 0.26vw 0.26vw 0.26vw 0px;
  width: 37.2vw;
  margin-top: 1vw;
}

.SecurityCard,
.SecurityCardLarge {
  @media (max-width : 575px) {
    display: block;
    text-align: center;
    padding: 16px;
  }
  display: grid;
  padding-bottom: 1.5vw;
}

.SecurityCardTitle {
  @media (max-width : 575px) {
    font-size: 20px;
    text-align: center;
    margin: 0;
    padding-bottom: 16px;
  }
  font-weight: 600;
  color: #ffffff;
  font-size: 1.25vw;
  margin-top: 1.4vw;
  margin-left: 1vw;
}

.SecurityCardIcon {
  @media (max-width : 575px) {
    width: auto !important;
    height: 4rem !important;
    margin-right: 0 !important; 
  }
  margin-left: auto;
  margin-right: 2.47vw;
}
