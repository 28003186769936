

.dashboard-select {
  margin: 15px;
  width: 350px;
  border-radius: 8px;
  border: 1px solid #ced4da;
  background: linear-gradient(145deg, #ffffff, #e9ecef);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1),
    1px 1px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-select:hover {
  background: linear-gradient(145deg, #e9ecef, #ffffff);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1),
    1px 1px 4px rgba(0, 0, 0, 0.3);
}

.dashboard-select .ant-select-selector {
  border: none;
  background: transparent;
}

.dashboard-select .ant-select-arrow {
  color: #007bff;
}

.dashboard-col {
  gap: 20px;
}

.dashboard-row {
  height: 300px;
  gap: 20px;
  margin-bottom: 10px;
}

.finding-cards {
  width: 100%;
}

.dashboard-piechart {
    padding: 40px;
    box-shadow: 5px 5px 10px #888888;
    border-radius: 15px;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

