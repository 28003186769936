.LandingPage {
  color: #ffffff;
}

.TopContainer {
  @media (max-width : 575px) {
    height: auto;
    background-size: auto;
    background-position: center;
    background-image: url('../images/mainPageMobile.svg');
  }
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-image: url('./assets/HomeBg.svg');
}

.HeaderAndSection1 {
  padding: 1.8vw 10vw;
}

.Header {
  display: flex;
  justify-content: space-between;
  font-family: 'Jost', sans-serif;
  align-items: center;
}

.LogoWithText img {
  width: 12.13vw;
}

.LinksAndButtons {
  display: flex;
  column-gap: 1.9vw;
  align-items: center;
}

.LinksAndButtons .Link {
  font-size: 0.83vw;
  font-weight: 500;
  cursor: pointer;
}

.Button {
  width: 8vw;
  height: 2vw;
}

.WelcomeContainer {
  @media (max-width : 575px) {
    margin-top: 40%;
    margin-bottom: 25%;
    line-height: 2;
  }
  margin-top: 7vw;
  font-family: 'Poppins', sans-serif;
}

.WelcomeText {
  @media (max-width : 575px) {
    font-size: 18px;
    font-weight: 500;
  }
  font-size: 1.45vw;
  font-weight: normal;
}

.Slogan {
  @media (max-width : 575px) {
    font-size: 38px;
    width: auto;
  }
  font-weight: bold;
  font-size: 3.59vw;
  width: 54vw;
  line-height: 1.2;
  margin-top: 0.65vw;
}

.WelcomeContainer .Description {
  @media (max-width : 575px) {
    font-size: 16px;
    width: auto;
    text-align: left;
  }
  font-size: 1.14vw;
  color: #ffffff;
  width: 74vw;
  margin-top: 1vw;
}

.GetStartedButton {
  @media (max-width : 575px) {
    width: auto;
    height: auto;
  }
  margin-top: 2vw;
  width: 10.9vw;
  height: 3.5vw;
}

.GetStartedButton > div {
  @media (max-width : 575px) {
    font-size: 16px;
    width: fit-content;
    height: auto;
    padding: 10px;
  }
  background: transparent linear-gradient(180deg, #e7f7f8 0%, #5b9bd6 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.93vw;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.EC2PlannerContainer {
  @media (max-width : 575px) {
    margin: 18px 0;
  }
  display: flex;
  align-items: center;
  column-gap: 1.1vw;
  float: right;
}

.EC2PlannerContainer .Text {
  @media (max-width : 575px) {
    font-size: 12px;
  }
  font-size: 1.14vw;
  font-weight: 500;
  color: #d4eaf3;
}

.CalculateButton {
  @media (max-width : 575px) {
    width: fit-content;
    height: auto;
    width: fit-content;
  }
  width: 9.9vw;
  height: 2.6vw;
}

.CalculateButton > div {
  @media (max-width : 575px) {
    font-size: 12px;
    padding: 5px;
    margin-left: 5px;
  }
  background: transparent linear-gradient(180deg, #e7f7f8 0%, #5b9bd6 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.93vw;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.DiscoverContainer {
  background: #000000;
  padding: 5vw 10vw;
  position: relative;
}

.SlicesLeft {
  position: absolute;
  top: 0vw;
  left: -12vw;
}

.SlicesLeft img {
  width: 30vw;
}

.DiscoverContainer .Title {
  @media (max-width : 575px) {
    font-size: 25px;
  }
  font-weight: 900;
  font-size: 2vw;
  color: #ffffff;
}

.DiscoverContainer .Subtitle {
  @media (max-width : 575px) {
    width: auto;
    font-size: 13px;
  }
  width: 67.5vw;
  font-size: 0.93vw;
}

.FeatureCards {
  @media (max-width : 400px) {
    display: flex;
    flex-direction: column;
  }
  display: flex;
  column-gap: 1vw;
  margin-top: 4.42vw;
}

.StartContainer {
  @media (max-width : 575px) {
    padding: 10vw;
    flex-direction: column;
    height: auto;
    text-align: left;
    align-items: flex-start;
  }
  height: 16.9vw;
  align-items: center;
  display: flex;
  position: relative;
  justify-content: space-between;
  background: transparent linear-gradient(0deg, #0e213e 0%, #05204a 100%) 0% 0% no-repeat padding-box;
  padding: 1.8vw 10vw;
}

.StartContainer > div:first-child {
  @media (max-width : 575px) {
    width: auto;
    font-size: 30px;
    font-weight: 900;
  }
  font-weight: 600;
  font-size: 2.3vw;
  color: #ffffff;
  width: 35vw;
}

.LetsStartButton {
  @media (max-width : 575px) {
    height: auto;
    width: fit-content;
    margin: 10px 0;
  }
  width: 13.5vw;
  height: 3.43vw;
}

.LetsStartButton > div {
  @media (max-width : 575px) {
    font-size: 16px;
    padding: 10px;
  }
  background: transparent linear-gradient(180deg, #e7f7f8 0%, #5b9bd6 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.93vw;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.DolphinLogo {
  @media (max-width : 575px) {
    position: absolute;
    right: 10px;
    top: -40px;
  }
  position: absolute;
  right: 25vw;
  top: 0;
}

.DolphinLogo img {
  @media (max-width : 575px) {
    width: 220px;
    height: 220px;
  }
  width: 21.6vw;
  height: 12.6vw;
}

.CardContainer {
  margin-top: 3vw;
  display: flex;
  column-gap: 1vw;
  align-items: flex-end;
}

.CostVideoContainer {
  @media (max-width : 575px) {
    padding: 24px 24px 80px 24px;
  }
  background: #0e213e;
  padding: 1.8vw 10vw;
  position: relative;
}

.CostVideoContainer .Title {
  @media (max-width : 575px) {
    font-size: 32px;
  }
  font-weight: 900;
  font-size: 2vw;
  color: #ffffff;
}

.CostVideoContainer .Subtitle {
  @media (max-width : 575px) {
    font-size: 16px;
    width: auto;
  }
  width: 51.51vw;
  font-size: 0.93vw;
}

.SlicesRight {
  position: absolute;
  top: 0vw;
  right: 0;
}

.SlicesRight img {
  width: 30vw;
}

.CostVideo {
  @media (max-width : 575px) {
    width: auto;
    height: auto;
    margin-bottom: 70px
  }
  position: relative;
  margin-top: 1vw;
  margin-bottom: 4vw;
  width: 39.79vw;
  height: 22.3vw;
  border: 1px solid #0a35a7;
  border-radius: 0.2vw;
}

.CostVideoFooter {
  @media (max-width : 575px) {
    width: 95%;
    top: 100px;
  }
  width: 36.04vw;
  opacity: 0.95;
  position: absolute;
  bottom: -4vw;
  right: 0;
}

.CostVideoTitle {
  @media (max-width : 575px) {
    height: auto;
    font-size: 16px;
  }
  background: #686868 0% 0% no-repeat padding-box;
  padding: 0.57vw 1.3vw;
  font-weight: 600;
  font-size: 1.25vw;
  color: #ffffff;
  height: 2.86vw;
  width: fit-content;
}

.CostVideoDesc {
  @media (max-width : 575px) {
    font-size: 12px;
  }
  background: #0a121d 0% 0% no-repeat padding-box;
  font-size: 0.93vw;
  color: #ffffff;
  width: 100%;
  padding: 1.77vw 2.29vw;
}

.CostVideosContainer {
  @media (max-width : 575px) {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1vw;
  grid-row-gap: 1vw;
}

.LearnMoreContainer {
  @media (max-width : 575px) {
    font-size: 10px;
  }
  color: #5b9bd6;
  text-transform: uppercase;
  font-size: 0.67vw;
  font-weight: 600;
  margin-top: 0.8vw;
  cursor: pointer;
}

.LearnMoreBorder {
  background: #5b9bd6 0% 0% no-repeat padding-box;
  height: 3px;
  width: 1vw;
}
