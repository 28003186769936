.data-sets-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.data-set {
  flex: 1;
  padding: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.data-set h3 {
  margin-bottom: 15px;
  text-align: center;
}

.details-filters-container {
  display: flex;
  width: fit-content;
  flex-direction: row;
  gap: 15px;
  padding: 10px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
}

.filters-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 10px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-item {
  display: flex;
  align-items: center;
}

.filter-label {
  font-weight: bold;
  text-align: right;
  color: #007bff;
}

.filter-select {
  width: 230px;
  border-radius: 8px;
  border: 1px solid #ced4da;
  background: linear-gradient(145deg, #ffffff, #e9ecef);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease, box-shadow 0.3s ease;
}

.filter-select:hover {
  background: linear-gradient(145deg, #e9ecef, #ffffff);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1), 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.filter-select .ant-select-selector {
  border: none;
  background: transparent;
}

.filter-select .ant-select-arrow {
  color: #007bff;
}

.details-type {
  display: flex;
  align-items: center;
  padding: 10px;
}

.date-selection-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.date-label {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #007bff !important;
}

.compare-controls {
  display: flex;
  gap: 20px;
}

.details-type-container {
  width: 200px;
}

.submit-button {
  border-radius: 5px !important;
}

.submit-button:hover {
  background-color: #0056b3;
}

.back-btn {
  margin: 10px;
  border-radius: 5px;
  background: none;
  border: transparent;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0 10px;
}

.trend-up {
  background-color: #ffebee;
}

.trend-down {
  background-color: #e8f5e9;
}

.trend-up:hover,
.trend-down:hover {
  opacity: 0.8;
}

.billing-compare-wrapper {
  padding: 20px;
}

.billing-compare-results {
  position: relative;
}

.back-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-bottom: 16px;
}

.billing-compare-table {
  border-radius: 4px;
  border-collapse: collapse;
  width: 100%;
  padding: 10px;
}

.billing-compare-table .ant-table {
  background: transparent;
}

.billing-compare-table .ant-table-thead > tr > th {
  background-color: #0054a1;
  color: white;
  border-color: white;
  text-align: center;
  font-weight: bold;
  white-space: normal;
  height: auto;
  padding: 16px 8px;
}

.billing-compare-table .ant-table-thead > tr > th .ant-table-column-title {
  white-space: normal;
}

.billing-compare-table .ant-table-tbody > tr > td {
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}

.billing-compare-table .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black;
}

.billing-compare-table .ant-table-tbody > tr > td {
  text-align: center;
}

.billing-compare-table .ant-table-tbody > tr.trend-up {
  background-color: #fff1f0;
}

.billing-compare-table .ant-table-tbody > tr.trend-down {
  background-color: #f6ffed;
}

.billing-compare-table .ant-table-tbody > tr:hover > td {
  background-color: #e6f7ff;
}
