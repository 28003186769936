.dataTable-container {
  margin: 20px;
  overflow-x: auto; /* Ensure horizontal scrolling if needed */
}

.custom-dataTable {
  border-radius: 10px;
  width: 100%;
  table-layout: fixed; /* Use fixed layout for better column width handling */
}

.custom-dataTable thead th {
  background-color: #f5f5f5;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping */
}

.custom-dataTable tbody td {
  color: #666;
  border-bottom: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping */
}

.custom-dataTable thead th.custom-header {
  background: rgb(3, 3, 90);
  color: white;
  font-weight: 600;
}

.custom-dataTable tbody tr td {
  border-right: 1px solid #ddd;
}

.custom-dataTable tbody tr:last-child td {
  border-bottom: none;
}

.custom-dataTable tbody tr td:last-child {
  border-right: none;
}

/* Add specific column width if needed */
.custom-dataTable .account-id {
  width: 150px;
}

.custom-dataTable .region {
  width: 150px;
}

.custom-dataTable .service {
  width: 150px;
}

.custom-dataTable .check-id {
  width: 150px;
}

.custom-dataTable .check-title {
  width: 200px;
}

.custom-dataTable .status {
  width: 120px;
}

.custom-dataTable .status-details {
  width: 200px;
}

.custom-dataTable .severity {
  width: 120px;
}

.status-text.pass {
  color: green;
}

.status-text.fail {
  color: red;
}

.status-text.manual {
  color: darkblue;
}

.severity-text.low {
  color: blue;
  font-weight: bold;
}

.severity-text.medium {
  color: brown;
  font-weight: bold;
}

.severity-text.high {
  color: red;
  font-weight: bold;
}

.severity-text.critical {
  color: lightcoral;
  font-weight: 950;
}

@media (max-width: 768px) {
  .custom-dataTable {
    font-size: 14px;
  }
}
