.FaqItem {
  padding: 10px 10px 10px 0;
  cursor: pointer;
  border-bottom: 1px solid #8f8da2;
}

.Expandable > div:last-child {
  border-bottom: none;
}

.Title {
  @media (max-width : 575px) {
    font-size: 18px;
    align-items: baseline;
  }
  font-size: 0.93vw;
  font-weight: 600;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ExpandedTitle {
  color: #3d72fe;
}

.Subtitle {
  @media (max-width : 575px) {
    font-size: 18px;
    height: auto;
  }
  font-size: 0.83vw;
  font-weight: normal;
  color: #eae8ff;
}

.ExpandIcon img{
  @media (max-width : 575px) {
    height: 15px;
    width: 15px;
  }
 height: 1.6vw;
 width: 1.6vw;
}

.SubtitleContainer {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease; 
}

.Expanded .SubtitleContainer {
  @media (max-width : 575px) {
    max-height: 100vh;
  }
  max-height: 20px; 
}