table.costTable {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 95%;
  text-align: center;
}

.table-info-small-font {
  font-size: 16px;
}

/*
table.costTable tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

table.costTable tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
*/

table.costTable thead {
  height: 62px;
}

table.costTable td,
table.costTable th {
  padding: 15px 2px;
}
table.costTable tbody td {
  border-bottom: 1px solid #d8e4ef;
}

table.costTable thead {
  background-color: #eef6ff;
  /* background: linear-gradient(180deg, #EEF6FF -3.23%, #F5FAFF 100%);*/
  border-bottom: 5px solid #ffffff;
  border-collapse: collapse;
}

table.costTable thead tr th:first-child {
  font-weight: 400;
  font-size: 21px;
  /*   line-height: 24px;*/
  color: #202020;
  text-align: left;
  padding-left: 40px;
}

table.costTable thead th {
  font-size: 17px;
  font-weight: bold;
  color: #000000;
  text-align: center;
}

tr.only-row td {
  padding-top: 25px;
  padding-bottom: 25px;
}

tr.sum-row td:first-child {
  font-weight: 700;
}
tr.sum-row td:not(:first-child) {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #666666;
}

table.costTable tbody tr td:first-child {
  text-align: left;
  padding-left: 40px;
}
.rdfn-search-btn {
  background: #5b9bd6;
  color: #ffffff !important;
  border-radius: 40px;
}
.export-btn {
  background: #ffffff;
  color: #5b9bd6 !important;
  border: 1px solid #5b9bd6;
  border-radius: 40px;
}
.ant-col-p0 {
  padding: 0px !important;
}
.pricingTable {
  height: 90%;
  overflow: auto;
}

.pricingTable .ant-table-wrapper {
  height: 100%;
}

.pricingTable .ant-spin-nested-loading,
.pricingTable .ant-spin-container {
  height: 100%;
}
.pricingTable .ant-table {
  max-height: 100%;
  border-bottom: none;
}
.pricingTable .ant-table-container {
  display: contents;
}
.pricingTable .ant-table-content {
  overflow: auto !important;
  display: contents;
}
.pricingTable table thead tr th {
  background: linear-gradient(180deg, #eef6ff -3.23%, #f5faff 100%);
  justify-content: center;
  color: #202020;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid #d8e4ef;
  text-align: center;
}
.pricingTable
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.pricingTable .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  border-bottom: 1px solid #d8e4ef;
}
.pricingTable .ant-table-container {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  overflow: hidden;
}
.pricingTable .border-rt {
  border-right: 1px solid #d8e4ef;
}
.pricingTable .border-btm {
  border-bottom: 1px solid #d8e4ef;
}
/* .pricingTable table:hover,tr:hover,thead:hover {
    background: none !important;
}

.pricingTable .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}
.pricingTable .ant-table-tbody > tr.ant-table-row-level-1:hover > td {
    background: unset;
  } */

.ant-drawer {
  z-index: 1050 !important;
}

.cost-planner-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}
