.legal-container {
  display: grid;
  justify-content: center;
  margin: 2vw 10vw;
  max-width: 800px;
  font-size: 1.1vw;
}

.legal-container .text {
  text-align: left;
  margin: 0 1rem;
  font-size: 1.1vw;
}
.legal-container .heading {
  text-align: left;
  margin: 0 1rem;
  font-size: 2.4vw;
  font-weight: 600;
}
.legal-container .sub-heading {
  text-align: left;
  margin: 0 1rem;
  font-size: 0.8vw;
  font-weight: 600;
  margin-bottom: 1vw;
  color: #333;
}

.legal-container .p {
  font-size: 1.1vw;
}

.legal-container .li {
  font-size: 1.1vw;
}
