.Header {
  @media (max-width : 575px) {
    height : 300px;
  }
  height: 24.5vw;
}

.Container {
  @media (max-width : 575px) {
    flex-direction: column;
  }
  display: flex;
}

.LeftContainer {
  @media (max-width : 575px) {
    width: auto;
  }
  width: 28vw;
}

.RightContainer {
  @media (max-width : 575px) {
    width: auto;
  }
  width: 50vw;
}

.Title {
  @media (max-width : 575px) {
    font-size: 24px;
    margin: 20px 0;
  }
  font-weight: 900;
  font-size: 2.6vw;
  color: #ffffff;
  line-height: 1.1;
}

.Subtitle {
  @media (max-width : 575px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
  font-size: 0.93vw;
  color: #ffffff;
  margin-top: 1vw;
}

.Contact {
  background: #000000;
  padding: 1.8vw 10vw;
}

/* Form css */

.inputRow {
  @media (max-width : 575px) {
    flex-direction: column;
    row-gap: 20px;
  }
  display: flex;
  column-gap: 1vw;
}

.inputFieldContainer {
  width: 100%;
}

.inputField {
  width: 100%;
  padding: 1vw;
  border-radius: 0.2vw;
  border: none;
  outline: none;
}

.textareaField {
  @media (max-width : 575px) {
    margin-top: 20px;
  }
  width: 100%;
  padding: 1vw;
  border-radius: 0.2vw;
  border: none;
  outline: none;
  margin-top: 1vw;
}

.errorMessage {
  font-size: 1vw;
  color: red;
  margin-top: 5px;
  margin-left: 0.51vw;
  margin-bottom: 0.1vw;
}

.submitContactButton{
  @media (max-width : 575px) {
    width: auto;
    height: auto;
    margin-top: 20px;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 10px;
  }
  width: 16.6vw;
  height: 2.6vw;
  margin-top: 2vw;
  background: transparent linear-gradient(180deg, #5d54fc 0%, #001182 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.93vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
}
