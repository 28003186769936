.CostVideoContainer {
  @media (max-width : 575px) {
    padding: 24px;
  }
  color: #ffffff;
  background: #080c12;
  padding: 1.8vw 10vw;
  position: relative;
}

.Header {
  @media (max-width : 575px) {
    height: 300px;
  }
  height: 24.5vw;
}

.CostVideoContainer .Title {
  @media (max-width : 575px) {
    font-size: 26px;
    margin: 16px 0;
  }
  font-weight: 900;
  font-size: 2vw;
  color: #ffffff;
}

.CostVideoContainer .Subtitle {
  @media (max-width : 575px) {
    width: auto;
    margin-bottom: 32px;
    font-size: 16px;
    text-align: left;
  }
  width: 67.51vw;
  font-size: 0.83vw;
  color: #c7c7c7;
}

.CostVideo {
  @media (max-width : 575px) {
    height: auto;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10%;
    border-radius: 0 0 5px 5px;
  }
  position: relative;
  margin-top: 1vw;
  margin-bottom: 1vw;
  height: 18.3vw;
  border: 1px solid #0a35a7;
  border-radius: 0.2vw;
}

.CostVideoFooter {
  width: 36.04vw;
  opacity: 0.95;
  position: absolute;
  bottom: -4vw;
  right: 0;
}

.CostVideoTitle {
  background: #686868 0% 0% no-repeat padding-box;
  padding: 0.57vw 1.3vw;
  font-weight: 600;
  font-size: 1.25vw;
  color: #ffffff;
  height: 2.86vw;
  width: fit-content;
}

.CostVideoDesc {
  background: #0a121d 0% 0% no-repeat padding-box;
  font-size: 0.93vw;
  color: #ffffff;
  width: 100%;
  height: 10.88vw;
  padding: 1.77vw 2.29vw;
}

.CostVideosContainer {
  @media (max-width : 575px) {
    display: flex;
    flex-direction: column;
  }
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 1vw;
  grid-row-gap: 1vw;
}

.LearnMoreContainer {
  color: #5b9bd6;
  text-transform: uppercase;
  font-size: 0.67vw;
  font-weight: 600;
  margin-top: 0.8vw;
  cursor: pointer;
}

.LearnMoreBorder {
  background: #5b9bd6 0% 0% no-repeat padding-box;
  height: 3px;
  width: 1vw;
}

.CloudCard {
  @media (max-width : 575px) {
    width: auto;
    height: auto;
    border-radius: 5px;
  }
  width: 39.5vw;
  height: 32.7vw;
  background: transparent linear-gradient(101deg, #000000 0%, #1e4582 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #08277b;
  border-radius: 0.26vw;
}

.CloudCardTitle {
  @media (max-width : 575px) {
    font-size: 18px;
    margin: 10px;
  }
  font-weight: 600;
  font-size: 1.25vw;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 2.86vw;
  margin-top: 3.5vw;
  margin-bottom: 1.25vw;
}

.CloudCardSubTitle {
  @media (max-width : 575px) {
    font-size: 16px;
    margin: 10px;
    text-align: left;
  }
  font-size: 0.83vw;
  color: #ffffff;
  margin-left: 2.86vw;
}

.CloudCardContainer {
  @media (max-width : 575px) {
    padding: 24px;
  }
  color: #ffffff;
  background: #080c12;
  padding: 1.8vw 10vw;
  position: relative;
}

.CloudCardVideo {
  @media (max-width : 575px) {
    height: auto;
    width: 100%;
  }
  height: 18vw;
  width: 80%;
  margin-top: 3.8vw;
}
