.navbar-light .navbar-nav .nav-link {
  color: #202020;
  font-family: "Inter", sans-serif;
}
.App {
  position: relative;
}
.App .stripe {
  background: url(../images/under-construction.jpeg) repeat-x;
  width: 100%;
  height: 60px;
}
.App .stripe span {
  width: 100%;
  background: #d7b00a;
  display: inline-block;
  margin-top: 13px;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  color: #d12b22;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.btn-theme {
  background: #ffffff;
  border: 1px solid #5b9bd6;
  color: #5b9bd6;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 16px 20px;
  line-height: 1;
}
/* Menu */
.navbar-expand .navbar-nav a.nav-link {
  font-size: 12px;
  line-height: 16px;
  /* margin-top: 10px; */
}
.navbar-expand .navbar-nav .signup-signin {
  /* background: #1c6dd0; */
  border-radius: 40px;
  display: flex;
  /* padding: 16px 24px; */
}
.navbar-expand .navbar-nav .signup-signin a {
  background: #fff;
  border: 1px solid #5b9bd6;
  border-radius: 40px;
  max-width: 151px;
  min-height: 48px;
  /* margin-top: 11px; */
  color: #5b9bd6;
}
.navbar-expand .navbar-nav {
  display: flex;
  align-items: center;
}
.navbar-expand .navbar-nav .nav-link {
  margin-right: 30px;
  letter-spacing: 1.5px;
  font-weight: 600;
}
.navbar-expand .navbar-nav .dropdown-toggle.nav-link {
  margin-right: 0px;
  width: 50px;
  height: 34px;
}
.navbar-expand .navbar-nav .signup-signin .nav-link,
.navbar-expand .navbar-nav .signup-signin span {
  padding: 15px 20px;
  letter-spacing: 1.25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}
.navbar-expand .navbar-nav .signup-signin .nav-link {
  margin-right: 24px;
}
.navbar-expand .navbar-nav .signup-signin .nav-link:last-child {
  margin-right: 0;
  background: #5b9bd6;
  border: 1px solid #5b9bd6;
  color: #fff;
}
.navbar-expand .navbar-nav .dropdown-menu {
  right: -11px;
  top: calc(100% + 7px);
}
.navbar {
  padding: 16px 0 16px 0;
}
.signup-signin {
}
/* Footer */

footer {
  height: 20px;
  background: #ccc;
  height: 260px;
  padding: 84px 0 85px 0;
  background: linear-gradient(90.74deg, #5b9bd6 0.45%, #667db6 94.99%);
}
footer span,
footer a {
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
footer .company img {
  margin-right: 8px;
}
footer .social-links {
  margin-top: 27px;
}
footer .social-links a {
  margin-right: 16px;
}
footer .social-links a:last-child {
  margin-right: 0;
}
footer h3 {
  font-size: 19px;
  color: #f2f2f2;
  letter-spacing: -0.5px;
  margin-bottom: 12px;
}
footer ul {
  padding: 0;
}
footer ul li {
  list-style: none;
  margin-bottom: 8px;
}
footer ul li a,
footer ul li a:hover {
  text-decoration: none;
  color: #fff;
}
footer .footer-content > div {
  margin-right: 45px;
}
footer .footer-content > div:last-child {
  margin-right: 0;
}
footer .contact button {
  background: #ffffff;
  border: 1px solid #5b9bd6;
  border-radius: 40px;
  max-width: 151px;
  min-height: 48px;
  margin-top: 11px;
  color: #5b9bd6;
}
/* Sign In and Sign Up */
.sign-container {
  max-width: 416px;
  padding-bottom: 40px;
  margin-bottom: 31px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 65px;
}
.sign-container .title {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.25px;
  font-weight: 400;
}
.micro-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #3f95ff;
  margin: 80px 0 16px 0;
  letter-spacing: 1.5px;
}
.sign-container input {
  background: #f9fafb;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  margin-bottom: 23px;
}
.sign-container .form-group {
  padding: 0 30px 0 30px;
}
.sign-container .sign-button {
  padding: 0 30px 0 30px;
}
button[type="submit"],
.button {
  background: #5b9bd6;
  border-radius: 40px;
  color: #fff;
  width: auto !important;
  font-size: 14px;
  line-height: 16px;
  padding: 15px 27px;
  text-transform: uppercase;
  margin-top: 6px;
  border-color: #5b9bd6;
}
.button {
  border-color: transparent;
}
.sign-container .sign-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  margin: 40.5px 0 0px 0;
}
.sign-container .sign-link a {
  color: #3f95ff;
  text-decoration: none;
}
.title-section {
  margin-top: 0;
  justify-content: center;
  padding-top: 22px;
}
.title-section img {
  width: 22px;
  height: 14px;
  margin-right: 10px;
}
.title-section .micro-title {
  margin-top: 0;
}
.sign-container.contact-us {
  border: 0px solid #c4c4c4;
}

input.form-control::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
}

input.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
}

input.form-control::placeholder {
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
}

.page-title {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 1.5px;
}
.accordion {
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 45px 30px 15px 30px;
}
.accordion .btn {
  text-align: left;
}
.accordion .btn-link {
  text-decoration: none;
}
.accordion .card {
  border: 0;
  padding: 0 10px;
  margin-bottom: 35px;
}
.accordion .card-body {
  flex: 1 1 auto;
  padding: 1rem 0px 0rem 0px;
  font-size: 16px;
  line-height: 24px;
  color: #606060;
  letter-spacing: 1.5px;
}
.accordion .accord-title {
  border: 0;
  background: transparent;
  font-size: 24px;
  line-height: 16px;
}
.accordion .accord-title {
  position: relative;
  text-align: left;
  padding-left: 20px;
}
.accordion .accord-title:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 6px;
  left: 0;
}
.accordion .accord-title::after {
  content: "";
  position: absolute;
  border: solid #5b9bd6;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  right: 10px;
  top: 5px;
}
.accordion .accord-title.show::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: 11px;
}
.profile-details {
  display: flex;
  align-items: center;
  position: relative;
}
.profile-details .dropdown-item {
  text-decoration: none;
}
.profile-details .dropdown-item a {
  color: #1e2125;
  text-decoration: none;
}
.profile-details .dropdown-item a:hover {
  background-color: #e9ecef;
}
.profile-details > img {
  max-width: 34px;
  position: absolute;
  right: 18px;
}
.navbar-expand .navbar-nav a.nav-link.dropdown-toggle:empty::after {
  margin-left: 0px;
  position: absolute;
  right: 0;
  top: 16px;
}
.resource-page .canvas {
  flex: 1 1 auto;
  overflow: hidden;
  margin: 0 10px;
  width: 60%;
}
.resource-page .context {
  flex: 0 1 22%;
}
.my-profile-page form .form-group {
  margin-bottom: 16px;
}
.details h2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #fff;
}
.bold {
  font-weight: bold;
}
.text-capitalize {
  text-transform: capitalize;
}
.custom-button {
  padding: 15px 20px;
  letter-spacing: 1.25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 40px;
}
.filled-button,
.filled-button:hover {
  margin-right: 0;
  background: #5b9bd6;
  border: 1px solid #5b9bd6;
  color: #fff;
}
.white-button,
.white-button:hover {
  background: #fff;
  border: 1px solid #5b9bd6;
  min-height: 48px;
  color: #5b9bd6;
}

.float {
  position: fixed;
  bottom: 40px;
  left: 40px;
}

.my-float {
  margin-top: 22px;
  border-radius: 25px;
  background-color: #ffffff;
  padding: 5px 15px 5px 15px;
}

.profile-input-field {
  width: 408px;
  margin-left: 25px;
}

/* The ribbon */

.corner-ribbon {
  width: 162px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 18px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 10;
}

.corner-ribbon.sticky {
  position: fixed;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.corner-ribbon.top-left {
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.blue {
  background: #39d;
}
.corner-ribbon.maroon {
  background: #a1280f;
}

.tooltip-inner {
  max-width: 250px !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  padding: 0vw 1vw;
}

.ant-select-dropdown,
.ant-picker-dropdown {
  z-index: 10000;
}

.ant-nested-table {
  overflow: hidden !important;
}

.ant-nested-table thead th,
.ant-nested-table tbody td {
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Ensure overflow is visible */
}

.ant-nested-table thead th {
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping */
}

.ant-nested-table tbody td {
  max-width: none;
}

.ant-recommedations-table {
  width: 100%;
}

.ant-recommedations-table th {
  background: rgb(226, 226, 226) !important;
  text-align: center !important;
}

.ant-recommedations-table thead th {
  text-align: left !important;
  white-space: nowrap !important;
}

.ant-recommedations-table td {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
}

.ant-recommedations-table tr > td {
  padding: 8px;
}

.ant-recommedations-table th,
.ant-recommedations-table td {
  overflow: visible !important;
  text-overflow: clip;
  white-space: normal;
  padding: 8px;
}

.ant-recommedations-table tr > th,
.ant-recommedations-table tr > td {
  padding: 4px 5px !important;
  border-right: 1px solid gainsboro !important;
}

.ant-odd-row,
.ant-odd-row td,
.ant-odd-row .ant-table-cell {
  background-color: #f0f0f0; /* Light gray background for even rows */
  border-bottom: 1px solid gainsboro !important;
}

.ant-even-row,
.ant-even-row td,
.ant-even-row .ant-table-cell {
  background-color: #ffffff; /* White background for odd rows */
}

.recommendations-title-container {
  display: flex;
  gap: 1vw;
  align-items: center;
}

.ant-recommedations-table .ant-subtable-column {
  padding: 0 !important;
}

.table-bf table thead tr th {
  background: linear-gradient(180deg, #eef6ff -3.23%, #f5faff 100%);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.aws-access-keys table td {
  font-size: 12px !important;
  text-align: center !important;
}

.book-demo-button {
  background: #1e90ff;
  background-image: linear-gradient(to top, #1ecbffc7, #46e0cc);
  cursor: pointer;
  color: black;
  background: aqua;
  padding: 0.6vw 1vw;
  border-radius: 3vw;
  font-weight: bold;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s, box-shadow 0.2s;
}
