.flex{
    display: flex;
}
.flex-col{
    display: flex;
    flex-direction: column;
}
.inline-block{
    display: inline-block;
}
.inline-flex{
    display: inline-flex;
}
.a-i-c{
    align-items: center;
}
.align-flex-start{
    align-self: flex-start !important;
}
.just-cont-center{
    justify-content: center;
}
.just-cont-sb{
    justify-content: space-between;
}
.just-cont-sa{
    justify-content: space-around;
}
.grid{
    display: grid;
}
.grid-auto-cols{
    display: grid;
    grid-auto-columns: auto;
}
.grid-rpt-3fr{
    grid-template-columns: repeat(3,1fr);
}
.grid-rpt-2fr{
    grid-template-columns: repeat(2,1fr);
}
.grid-gap-6p{
    grid-gap: 6px;
}
.grid-gap-25p{
    grid-gap: 25px;
}
.wid-fit{
    width: fit-content;
}
.wid-95per{
    width: 95%;
}
.m-auto{
    margin: auto;
}
.m-l-34{
    margin-left: 34px;
}
.m-r-20{
    margin-right: 20px;
}

.m-l-20{
    margin-left: 20px;
}
.m-l-r-3{
    margin-left: 3px;
    margin-right: 3px;
}
.m-l-r-10{
    margin-left: 10px;
    margin-right: 10px;
}
.m-r-10{
    margin-right: 10px;
}
.m-r-60{
    margin-right: 60px;
}
.m-b-5{
    margin-bottom: 5px;
}
.m-b-20{
    margin-bottom: 20px;
}
.m-b-15{
    margin-bottom: 15px;
}
.m-b-75{
    margin-bottom: 75px;
}
.m-t-b-10{
    margin-top: 10px;
    margin-bottom: 10px;
}
.w-100per{
    width: 100%;
}
.w-100px{
    width: 100px;
}
.w-125px{
    width: 125px;
}
.w-150px{
    width: 150px;
}
.p-0{
    padding: 0px !important;
}
.p-5{
    padding: 5px !important;
}
.p-r-5{
    padding-right: 5px;
}
.p-r-10{
    padding-right: 10px;
}
.p-l-r-5{
    padding-left: 5px;
    padding-right: 5px;
}
.p-l-r-10{
    padding-left: 10px;
    padding-right: 10px;
}
.p-l-r-20{
    padding-left: 20px;
    padding-right: 20px;
}
.p-t-b-2{
    padding-top: 2px;
    padding-bottom: 2px;
}
.p-t-b-5{
    padding-top: 5px;
    padding-bottom: 5px;
}
.p-t-b-10{
    padding-top: 10px;
    padding-bottom: 10px;
}
.p-t-b-15{
    padding-top: 15px;
    padding-bottom: 15px;
}
.p-t-b-20{
    padding-top: 20px;
    padding-bottom: 20px;
}
.p-l-r-3{
    padding-left: 3px;
    padding-right: 3px;
}
.p-l-r-5{
    padding-left: 5px;
    padding-right: 5px;
}
.p-l-r-7{
    padding-left: 7px;
    padding-right: 7px;
}
.p-l-r-10{
    padding-left: 10px;
    padding-right: 10px;
}
.p-l-r-20{
    padding-left: 20px;
    padding-right: 20px;
}
.p-l-r-50{
    padding-left: 50px;
    padding-right: 50px;
}
.p-20{
    padding: 20px;
}
.fs-11{
    font-size: 11px;
}
.fs-12{
    font-size: 12px;
}
.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-18{
    font-size: 18px;
}
.fs-21{
    font-size: 21px;
}
.fw-500{
   font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}
.fstyle-italic{
    font-style: italic;
}
.brdr-blu{
    border : 1px solid #EEF6FF;
    border-radius: 8px;
}
.brdr-gry{
    border : 1px solid #C4C4C4;
    border-radius: 8px;
}
.ovrflo-hdn{
    overflow: hidden;
}
.h-40p{
    height: 40px;
}
.h-100per{
    height: 100%;
}
.h-50vh{
    height: 50vh;
}
.txt-white{
    color: #FFFFFF;
}
.txt-black{
    color: #000000;
}
.txt-cyan-blue{
    color: #6B7280;
}
.cursr-pntr{
    cursor: pointer;
}
.bg-blue-grad-grad{
    background: linear-gradient(180deg, #EEF6FF -3.23%, #F5FAFF 100%);
}
.bg-blue{
    background: #F0F7FF;
}
.clr-blu{
    color: #5B9BD6;
}

.clr-blk-202020{
    color : #202020
}
.clr-gry40{
    color: #666666;
}
.clr-white{
   color : #FFFFFF
}
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.disabled{
    cursor: not-allowed;
    opacity: 0.85;
}
.btn-hvr :hover{
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.brdr-rad-50per{
    border-radius: 50% !important;
}
.brdr-rad-8p{
    border-radius: 8px;
}
.brdr-rad-4p{
    border-radius: 4px;
}

.txt-align-rt{
    text-align: right;
}
.sqr-16p{
    height: 16px;
    width: 16px;
}