@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.toggleOut {
  animation: fadeout 1s;
  opacity: 0;
  display: none !important;
}

.toggleIn {
  animation: fadein 1s;
  opacity: 1;
}

.LoginContainer {
  display: flex;
  background: #fcfcfc;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.LoginContainer .leftContainer {
  width: 100%;
  position: relative;
  background: url('../landingPage/assets/HomeBg.svg');
  background-position: center;
}

.LoginContainer .leftContainer .formContainer,
.LoginContainer .leftContainer .formContainer2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -3vw;
}

.LoginContainer .leftContainer .formContainer .form {
  display: flex;
  flex-direction: column;
  gap: 0.646vw;
  padding: 2vw;
  background: white;
  border-radius: 1vw;  
}

.LoginContainer .leftContainer .formContainer .form .title {
  font-weight: 700;
  font-size: 2.646vw;
  color: #383b53;
}

.LoginContainer .leftContainer .formContainer .form .Inputs {
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}

.LoginContainer .leftContainer .formContainer .form .Inputs label {
  font-weight: 500;
  font-size: 0.926vw;
  color: #595d7b;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .boldLabel {
  font-weight: 700;
  font-size: 16px;
  color: #383b53;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .inputBox {
  padding: 0.794vw 1.058vw;
  border: 1px solid #9aa8c0;
  border-radius: 0.529vw;
  width: 23.81vw;
  margin-top: 0.3vw;
  font-size: 1vw;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .PasswordInput {
  position: relative;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .PasswordInput img {
  position: absolute;
  top: 35%;
  right: 1vw;
  font-size: 1vw;
  cursor: pointer;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .buttonContainer {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .buttonContainer .backButton {
  padding: 0.794vw 1.587vw;
  background: #fcfcfc;
  border: 0.132vw solid #192592;
  border-radius: 0.529vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: #192592;
  flex: 1;
  white-space: nowrap;
  height: 3.175vw;
  cursor: pointer;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .buttonContainer .LoginButton {
  padding: 0.794vw 1.587vw;
  background: #192592;
  border-radius: 0.529vw;
  font-weight: 700;
  font-size: 1.058vw;
  color: #fcfcfc;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  flex: 1;
  white-space: nowrap;
  height: 3.175vw;
  cursor: pointer;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .greenText {
  display: flex;
  align-items: center;
  gap: 1vw;
  font-weight: 600;
  font-size: 14px;
  color: #149461;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .helperLine {
  font-weight: 500;
  font-size: 0.926vw;
  color: #383b53;
  display: flex;
  align-items: center;
  gap: 0.2vw;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .helperLine .hint {
  font-weight: 500;
  font-size: 0.926vw;
  text-decoration-line: underline;
  color: #2d9592;
  cursor: pointer;
}

.LoginContainer .leftContainer .formContainer .form .Inputs .eye {
  width: 1.455vw;
}

.LoginContainer .rightContainer {
  width: 60%;
}

.LoginContainer .rightContainer .illustration {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.LoginLogo{
    margin-left: 3vw;
    margin-top: 2vw;
}

.LoginText{
    font-weight: bold;
    font-size: 2.5vw;
    width: 30vw;
    top: 0vw;
    text-align: center;
    margin-left: 1vw;
    line-height: 1.2;
    margin-top: 0.65vw;
    position: absolute;
    color: white;
}