.securityViolationsTable table thead tr th {
  background: linear-gradient(180deg, #eef6ff -3.23%, #f5faff 100%);
  justify-content: center;
  color: #202020;
  font-weight: 600;
  font-size: 12px;
  border-bottom: 1px solid #d8e4ef;
  text-align: center;
}

.securityViolationsTable table td {
  font-size: 11px;
  text-align: center;

}

.securityViolationsTable
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.securityViolationsTable .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  border-bottom: 1px solid #d8e4ef;
}
.securityViolationsTable .border-rt {
  border-right: 1px solid #d8e4ef;
}
.securityViolationsTable .border-btm {
  border-bottom: 1px solid #d8e4ef;
}
.border-rt-gry {
  border-right: 1px solid #d8e4ef;
}
.border-btm-gry {
  border-bottom: 1px solid #d8e4ef;
}
.border-lft-c4 {
  border-left: 1px solid #c4c4c4;
}
.color-ash {
  color: #616161;
}
.severity-high {
  background: #ff003d;
  border-radius: 2px;
}
.severity-medium {
  background: #ff9900;
  border-radius: 2px;
}
.severity-low {
  background: #1c6dd0;
  border-radius: 2px;
}
.violated-categories {
  background: #fafafa;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
}
.bg-grn {
  background: #53a258;
}
.sqr-20p {
  height: 20px;
  width: 20px;
}

.collapse-panel .ant-collapse-content-box {
  padding: 0 !important;
}

.collapse-panel .ant-collapse-header {
  align-items: 'center' !important;
}
