.dashboard {
  padding: 20px;
}

.billing-cards-container {
  display: flex;
  margin: 20px 0 20px 0;
}

.billing-card {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  background-color: #f9f9f9;
}

.left-align {
  text-align: left;
  padding-left: 8px;
}

.billing-table-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px;
  font-size: 14px;
}

th,
td {
  padding: 8px 12px;
  text-align: center;
  border: 1px solid #4d4d4d;
  color: black;
}

th {
  background-color: #0054a1;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

td.left-align {
  text-align: left;
}

.cell-default {
  background-color: #f0faff;
  color: black;
}

.cell-zero {
  background-color: #f0faff;
  color: black;
  position: relative;
  border: 1px solid #7a7a7a;
}

.cell-zero::before {
  content: "-";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
}

.cell-increase {
  background-color: #ffcccc;
  color: black;
}

.cell-decrease {
  background-color: #dff0d8;
  color: black;
}

table th,
table td {
  white-space: nowrap;
  text-align: center;
}

@media (max-width: 768px) {
  th,
  td {
    font-size: 14px;
  }

  .billing-table-container {
    margin: 0;
  }
}

.billing-cards-container {
  display: flex;
  justify-content: space-between;
}

.billing-card {
  flex: 1;
  padding: 13px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-right: 10px;
  text-align: center;
}

.billing-card h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
  font-weight: 800;
}

.billing-card p {
  font-size: 16px;
  color: black;
}

/* Billings */

.graph-btns {
  display: flex;
  margin: 0 28px 20px 10px;
}

.left-section {
  flex: 1;
  text-align: left;
}

.graph-button {
  padding: 10px;
  margin: 0 0 0 10px;
  border: none;
  border-radius: 4px;
  background-color: #0054a1;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.graph-button:hover {
  background-color: #0056b3;
}

.graph-button:active {
  background-color: #004085;
  transform: scale(0.98);
}

.graph-button:focus {
  outline: none;
}

.graph-button.show {
  background-color: #0056b3;
}

.graph-button.hide {
  background-color: #0056b3;
}

.right-section {
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.right-section .excel-button,
.right-section .pdf-button {
  padding: 10px;
  margin: 0 0 0 10px;
  border: none;
  border-radius: 4px;
  background-color: #0054a1;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.billing-custom-tabs .ant-tabs-tab {
  padding: 10px 215px;
  background: rgb(212, 214, 218);
  color: black;
  border-radius: 4px 4px 0 0;
  border: 1px solid #d9d9d9;
  border-bottom: none;
  transition: background-color 0.3s, color 0.3s;
}

.billing-custom-tabs .ant-tabs-tab:hover {
  background: #00274d;
  color: white;
}

.billing-custom-tabs .ant-tabs-tab-active {
  background: #0054a1;
  font-weight: bold;
}

.custom-tabs .ant-tabs-content {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 4px;
}

.tab-button-active {
  font-size: 16px;
  color: white;
}

.global-summary-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.global-summary-card {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  flex: 1;
  margin-right: 10px;
  text-align: center;
}

.global-summary-card:last-child {
  margin-right: 0;
}

.global-summary-card h3 {
  margin-bottom: 10px;
}

.global-summary-card p {
  font-size: 1.2em;
  font-weight: bold;
}

/* CSS for the title and account IDs */
.aggregate-title-container {
  text-align: center;
  margin-bottom: 20px;
}

.aggregate-title {
  font-size: 24px;
  font-weight: bold;
}

.aggregate-account-ids {
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: #555;
  margin-top: 5px;
}

/* CSS for global summary cards */
.global-billing-cards-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.global-billing-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  width: 20%;
  border: 2px solid #ccc;
}

.global-billing-card h3 {
  font-size: 26px;
  margin-bottom: 10px;
}

.global-billing-card p {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.accordion-header {
  display: flex;
  align-items: center;
}

.aws-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.aggregate-title-container {
  text-align: center;
  margin-bottom: 20px;
}

.aggregate-title {
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.aws-logo-aggregate {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  vertical-align: middle;
}

.aggregate-account-ids {
  font-size: 0.9rem;
  font-style: italic;
  color: #555;
  margin-left: 5px;
}
