/* Carousel container styles */
.carouselContainer {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  
  /* Carousel content styles */
  .carouselContent {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Individual slide styles */
  .carouselSlide {
    flex: 0 0 100%;
    width: 100%;
    /* Other slide styling */
  }
  
  .carouselSlide.active {
    display: block;
  }
  
  /* Dots container styles */
  .carouselDots {
    display: flex;
    justify-content: right;
    margin-top: 0;
  }
  
  /* Dot styles */
  .dot {
    width: 1.6vw;
    height: 1.6vw;
    background: #C7C7C7 0% 0% no-repeat padding-box;
    margin: 0 0.5vw;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .dot.activeDot {
    background-color: #0073b8;
  }
  
  .carouselSlide {
    flex: 0 0 100%;
    width: 100%;
    /* Other slide styling */
    transform: translateX(-100%); /* Start each slide outside the container */
    opacity: 0; /* Hide each slide initially */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add transitions */
  }
  
  .carouselSlide.active {
    transform: translateX(0); /* Slide the active slide into view */
    opacity: 1; /* Fade in the active slide */
  }